<template>
    <v-row no-gutters>
        <v-col
           cols="12"
           lg="4"
           md="4"
           sm="6"
           xs="12"
        >
            <v-text-field
                prepend-icon="mdi-phone"
                label="Celular"
                class="required pr-2"
                :rules="rulePhone"
                :value="celular"
                @click.stop="modalActualizarCelular"
                readonly
                filled
            >
                <template slot="append">
                    <v-icon  @click.stop="modalActualizarCelular">mdi-pencil-outline</v-icon>
                </template>
            </v-text-field>
        </v-col>
        <v-col
           cols="12"
           lg="4"
           md="4"
           sm="6"
           xs="12"
        >
            <v-text-field
                prepend-icon="mdi-phone"
                label="Whatsapp"
                class="required pr-2"
                :rules="rulePhone"
                :value="whatsapp"
                @click.stop="modalActualizarWhatsapp"
                readonly
                filled
            >
                <template slot="prepend">
                     <img src="/whatsapp-logo.png" width="30px" />
                </template>
                <template slot="append">
                    <v-icon  @click.stop="modalActualizarCelular">mdi-pencil-outline</v-icon>
                </template>
            </v-text-field>
        </v-col>
        <v-col
           lg="4"
           md="4"
           sm="6"
           xs="12"
        >
             <v-text-field
                prepend-icon="mdi-email"
                label="Correo Electrónico"
                class="required pr-2"
                :value="correo"
                :rules="ruleEmail"
                @click.stop="modalActualizarCorreo"
                readonly
                filled
            >
                <template slot="append">
                    <v-icon  @click.stop="modalActualizarCorreo">mdi-pencil-outline</v-icon>
                </template>
            </v-text-field>
        </v-col>
        <ActualizarContacto 
            v-if="dialog" 
            :dialog="dialog" 
            :url="url" 
            :label="label" 
            :value="valueEdit"
            :rules="rulesEdit"
            @modificado="modificarContacto"
            @cancelar="dialog=false"
        />
    </v-row>
</template>
<script>
import ActualizarContacto from "@/components/ActualizarContacto"
import { mapState } from "vuex"

export default {
    props:{
        edit:{
            type:Boolean,
            default:true
        }
    },
    components:{
        ActualizarContacto
    },
    data(){
        return{
            dialog:false,
            rulesEdit:'required',
            rulePhone:[v=>!!v||'Este Campo es Obligatorio'],
            ruleEmail:[v=>!!v||'Este Campo es Obligatorio', v => /.+@.+/.test(v) || 'Correo no valido']
        }
    },
    computed:{
         ...mapState({
            celular: state => state.abogado.persona.celular,
            correo:state=>state.abogado.persona.correo,
            whatsapp:state=>state.abogado.persona.whatsapp
        })
    },
    methods:{
        modalActualizarCorreo(){
            this.label = 'Correo'
            this.dialog = true 
            this.valueEdit=this.correo
            this.url = 'abogado-ciudadano/correo'
            this.rulesEdit='required email'
        },
        modalActualizarCelular(){
            this.label = 'Celular'
            this.valueEdit=this.celular
            this.dialog = true 
            this.url = 'abogado-ciudadano/celular'
            this.rulesEdit='required'
        },
        modalActualizarWhatsapp(){
            this.label = 'Whatsapp'
            this.valueEdit=this.whatsapp
            this.dialog = true 
            this.url = 'abogado-ciudadano/whatsapp'
            this.rulesEdit='required celular'
        },
        modificarContacto(data){
            this.$store.commit("abogado/setPersonaContacto", data);
            this.dialog=false
        }
    }
}
</script>