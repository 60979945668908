<template>
    <v-dialog
      v-model="dialog"
      width="500"
      persistent
      scrollable
    >
      <v-card :disabled="loading">
        <v-card-title class="subtitle-1 grey lighten-2">
          Actualizacion de Contacto
        </v-card-title>
        
        <v-card-text class="pt-4">
            <v-form ref="formActualizarContacto" v-model="valid">
            <v-text-field
                :label="label+' Actual'"
                class="required"
                 v-model="value"
                readonly
                outlined
            ></v-text-field>
            <v-text-field
                :label="'Nuevo '+label"
                class="required"
                v-model="data.valor"
                :rules="getRules(rules)"
                autofocus
            ></v-text-field>
            </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="$emit('cancelar')"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            :loading="loading"
            @click="realizarModificacion"
            :disabled="!valid"
          >
            actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import mixin_rules from "@/mixins/rules"
export default {
    mixins:[mixin_rules],
    props:{
        dialog:{
            type:Boolean,
            default:false
        },
        value:String,
        url:{
            type:String,
            default:''
        },
        label:{
            type:String,
            default:''
        },
        rules:{
            type: String,
            default: 'required'
        }
    },
    data(){
        return{
            data:{
                valor:null
            },
            loading:false,
            valid:true,
            required:[v=>!!v||'Este Campo es Obligatorio']
        }
    },
    methods:{
        realizarModificacion(){
            if(this.value===this.data.valor){
                this.$notify({
                    group:'foo',
                    type:'error',
                    title:'ERROR FORMULARIO',
                    text:'los campos deben ser distintos'
                })
                return
            }
            if(this.$refs.formActualizarContacto.validate()){
                this.loading=true
                this.$http.patch(`${this.$apiUrl}${this.url}`,this.data).then(response=>{
                    this.loading=false
                    this.$store.commit('abogado/setPersonaContacto',response.data.data)
                    this.$emit('modificado',response.data.data)
                }).catch(error=>{
                    this.loading=false
                    this.$notify({
                    group:'foo',
                    type:'error',
                    title:'ERROR',
                    text:error
                })
                })
            }
        },
        
    }
}
</script>